<template>
  <div class="movies_warp">
    <div class="info_top">
      <router-link to="/creationCenter"><svg-icon icon-class="RecruitUP" class="RecruitUP" /></router-link>
      <div class="search" @click="$router.push('/search')">
        <svg-icon icon-class="search_red"></svg-icon>
        <van-notice-bar :scrollable="false" color="#666666" background="#f6f6f6">
          <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false" :touchable="false">
            <van-swipe-item v-for="item in hotListAll" :key="item.key">{{ item.name }}</van-swipe-item>
          </van-swipe>
        </van-notice-bar>
      </div>
      <router-link to="/taskHall"><svg-icon icon-class="redEnvelope" class="redEnvelope" /></router-link>
      <div @click="showPopover = true" class="add">
        <van-popover
          v-model="showPopover"
          placement="bottom-end"
          @select="selectPopover"
          :overlay="true"
          trigger="click"
          :actions="actions"
          class="popover"
        >
          <template #reference>
            <svg-icon iconClass="add_red"></svg-icon>
          </template>
        </van-popover>
      </div>
    </div>
    <van-tabs
      v-model="activeName"
      class="my_tab oepnX"
      background="#ffffff"
      title-active-color="#ff678f"
      title-inactive-color="#666666"
      animated
      :scrollspy="false"
      :swipeable="true"
      @change="changeTab"
    >
      <van-tab :title="item.name" v-for="(item, index) in tabs" :key="item.path" :name="item.path" :class="`tab_view${index}`">
        <keep-alive>
          <router-view v-if="activeName == item.path" />
        </keep-alive>
      </van-tab>
    </van-tabs>
    <!-- <div class="search_box">
      <router-link class="search" to="/search">
        <svg-icon icon-class="search" />
      </router-link>
    </div> -->

    <!--公告-->
    <!-- <AnnouncementText /> -->
  </div>
</template>
<script>
import { Tab, Tabs } from 'vant';
import { setSessionItem } from '@/utils/longStorage';
// import AnnouncementText from '@/components/AnnouncementText';
import { Popover } from 'vant';
import { queryHotTagList } from '@/api/community';

export default {
  name: 'movies',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    // AnnouncementText,
    [Popover.name]: Popover,
  },
  data() {
    return {
      activeName: '',
      marqueeText: '',
      advList: [],
      showPopover: false,
      actions: [
        {
          text: '图文',
          icon: require('@/assets/png/graphics.png'),
          type: 'graphics',
        },
        {
          text: '视频',
          icon: require('@/assets/png/video.png'),
          type: 'video',
        },
      ],
      tabs: [
        {
          path: '/movies/softGirl',
          name: '软妹推荐',
        },
        {
          path: '/movies/yellow',
          name: '原创热播',
        },
        {
          path: '/movies/av',
          name: '国产特区',
        },
        {
          path: '/movies/domestic',
          name: '明星网红',
        },
        {
          path: '/movies/cartoon',
          name: '异域色情',
        },
        {
          path: '/movies/original',
          name: '异想世界',
        },
        // {
        //   path: '/movies/varietyShow',
        //   name: '综艺',
        // },
      ],
      hotListAll: [
        {
          id: '1',
          name: '请输入搜索关键词',
        },
      ],
    };
  },
  created() {
    this.activeName = this.$route.path;
    this.getHotList();
  },

  methods: {
    changeTab(name) {
      this.$router.replace({ path: name });
      setSessionItem('moviesPath', name);
    },
    selectPopover(action) {
      if (action.type === 'graphics') {
        this.showPopover = false;
        this.$router.push('/uploadGraphics');
        return;
      }
      if (action.type === 'video') {
        this.showPopover = false;
        this.$router.push('/uploadVIdeo');
        return;
      }
    },
    //热门推荐
    async getHotList() {
      let res = await this.$Api(queryHotTagList, undefined);
      if (res.code == 200) {
        let list = res.data.hot || [];
        list.forEach((e, index) => {
          if (!e.name) {
            list[index].name = '未知标签';
          }
        });
        this.hotListAll = list;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.info_top {
  background: #ffffff;
  padding: 6px 10px 10px 10px;
  border-bottom: 0.7px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /deep/ .notice-swipe {
    height: 50px;
  }

  /deep/ .van-notice-bar {
    flex: 1;
    height: 20px;
    line-height: 20px;
    background: #f6f6f6;
    padding: 0 5px;
    margin-right: 10px;
    .van-notice-bar__content {
      width: 100%;
      height: 100%;
    }
  }
  .redEnvelope {
    font-size: 20px;
  }
  .add {
    padding-left: 17.5px;
    svg {
      font-size: 25px;
    }
  }
  .search {
    flex: 1;
    background: #f6f6f6;
    margin: 0 20px 0 6.5px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    span {
      padding-left: 3px;
      font-size: 12px;
      color: #999999;
    }
  }
  .RecruitUP {
    width: 75.5px;
    height: 15px;
  }
}
/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
  height: 100%;
}

.movies_warp {
  position: relative;
  overflow: hidden;
  height: 100%;

  .left_icon {
    svg {
      font-size: 18px;
      padding-right: 8px;
    }
  }

  .my_tab {
    height: 100%;

    .tab_view0,
    .tab_view1,
    .tab_view2,
    .tab_view3,
    .tab_view4,
    .tab_view5 {
      box-sizing: border-box;
      height: $moviesHiegt;
      //overflow-y: auto;
    }

    /deep/ .van-tab {
      font-size: 16px;
      // flex: unset;
      // padding: 0 15px;
    }

    /deep/ .van-tabs__line {
      bottom: 18px;
      width: 20px;
      height: 4px;
      border-radius: 2px;
      background: linear-gradient(#ff678f, #ff678f);
    }
    /deep/ .van-tabs__wrap {
      border-bottom: 0.7px solid #e0e0e0;
    }
    /deep/ .van-tab__text {
      font-size: 18px;
    }
  }

  .search_box {
    height: 44px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;

    right: 10px;

    .search {
      > svg {
        font-size: 22px;
      }
    }
  }
}
</style>
